var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 ma-0 flex-grow-1 fill-height align-start flex-shrink-1 cc-tracks-table",class:{
    'theme--dark': _vm.$vuetify.theme.dark
  },style:({
    position: 'relative'
  }),attrs:{"fluid":""}},[_c('v-data-table',{style:({
      width: '100%',
      height: '0px !important',
      position: 'absolute',
      left: '0',
      top: '0'
    }),attrs:{"headers":_vm.headers,"items":_vm.entries,"options":_vm.tableOptions,"server-items-length":_vm.serverSide ? _vm.serverEntryCount : undefined,"fixed-header":true,"loading":_vm.isLoading,"disable-sort":!_vm.serverSide,"items-per-page":!_vm.serverSide ? 25 : undefined,"height":_vm.tableHeight,"multi-sort":false,"item-key":"trackNumber","footer-props":{
      'items-per-page-options': [5, 10, 25, 50],
      'items-per-page-text': _vm.$vuetify.breakpoint.mdAndUp
        ? _vm.$t('app.tracks.table.tracksPerPage')
        : ''
    }},on:{"update:options":function($event){_vm.tableOptions = $event},"current-items":function($event){return _vm.$emit('loaded', $event)}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('tr',{style:({
          cursor: _vm.player ? 'pointer' : undefined
        }),on:{"click":function($event){_vm.player ? _vm.playTrack(data.item.trackId, data.index) : undefined}}},[_vm._l((data.headers),function(header,i){return [(data.item[header.value])?_c('td',{key:i,staticClass:"text-center",class:{
              'cc-blue--text': _vm.isPlaying(data.item.trackId, data.index)
            }},[_vm._v(" "+_vm._s(data.item[header.value])+" ")]):(!_vm.player && header.value === 'playButton')?_c('td',{key:i,staticClass:"text-center"},[(!_vm.isPlaying(data.item.trackId, data.index))?_c('v-tooltip',{attrs:{"open-delay":500,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.playTrack(data.item.trackId, data.index)}}},on),[_c('v-icon',[_vm._v("mdi-play-circle-outline")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('app.tracks.table.playTrack'))+" ")]):_c('v-btn',{style:({
                pointerEvents: 'none'
              }),attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-play-circle")])],1)],1):(_vm.deleteButton && header.value === 'deleteButton')?_c('td',{key:i,staticClass:"text-center"},[_c('v-tooltip',{attrs:{"open-delay":500,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.$emit('delete', data.index)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('app.tracks.table.deleteTrack'))+" ")])],1):(_vm.addButton && header.value === 'addButton')?_c('td',{key:i,staticClass:"text-center"},[_c('v-tooltip',{attrs:{"open-delay":500,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.$emit('add', data.index)}}},on),[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('app.tracks.table.addTrack'))+" ")])],1):_c('td',{key:i})]})],2):_c('SwipeTrack',{attrs:{"playing":_vm.isPlaying(data.item.trackId, data.index),"artist":data.item.artist,"title":data.item.title,"duration":data.item.duration,"deleteEnabled":_vm.deleteButton,"addEnabled":_vm.addButton,"dragEnabled":_vm.dragHandle},on:{"play":function($event){return _vm.playTrack(data.item.trackId, data.index)},"delete":function($event){return _vm.$emit('delete', data.index)},"add":function($event){return _vm.$emit('add', data.index)}}})]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('app.tracks.table.noData'))+" ")]},proxy:true},(_vm.hidePageCount)?{key:"footer.page-text",fn:function(ref){
              var pageStart = ref.pageStart;
              var pageStop = ref.pageStop;
return [_vm._v(" "+_vm._s(((_vm.$t('app.tracks.table.page')) + " " + (Math.floor( pageStart / (pageStop - pageStart) ) + 1)))+" ")]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }