



































































import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import Swiper from 'swiper';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

import 'swiper/css/swiper.min.css';

@Component
export default class SwipeTrack extends Vue {
  @Prop({ required: true })
  title!: string;

  @Prop({ required: true })
  artist!: string;

  @Prop({ required: true })
  duration!: string;

  @Prop({ required: true })
  playing!: boolean;

  @Prop({ default: false })
  deleteEnabled!: boolean;

  @Prop({ default: false })
  addEnabled!: boolean;

  @Prop({ default: false })
  dragEnabled!: boolean;

  @Ref('titleText')
  titleText!: HTMLElement;

  swiperEnabled: boolean =
    process.env.VUE_APP_SWIPER_ENABLED?.toLowerCase() === 'true' ?? false;

  swiper: Swiper | null = null;

  textTween: TweenLite | null = null;

  createSwiper() {
    this.swiper = new Swiper(this.$el as HTMLElement, {
      loop: false,
      direction: 'horizontal',
      initialSlide: 1,
      slidesPerView: 'auto',
      allowSlideNext: false,
      allowSlidePrev: this.deleteEnabled,
      on: {
        slideChange: () => {
          if (
            window.innerHeight > window.innerWidth &&
            this.swiper?.activeIndex === 0
          ) {
            this.$emit('delete');
          }

          setTimeout(() => {
            this.swiper?.update();
          }, 100);
        }
      }
    });
  }

  updateSwiper() {
    // console.log('anyadtodo');
    // this.swiper?.destroy(true, true);
    // setTimeout(() => {
    //   this.createSwiper();
    // }, 50);
  }

  mounted() {
    if (this.swiperEnabled) {
      if (this.swiper) {
        this.swiper?.destroy(true, true);
        this.swiper = null;
      }
      this.createSwiper();
    }
  }

  beforeDestroy() {
    this.swiper?.destroy(true, true);
  }
}
