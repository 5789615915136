






































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Playlist, Track, Genre, Mood } from '../service/types';
import { formatSeconds, clone, sleep } from '../util';
import {
  TrackSearchOptions,
  searchTracks,
  Filter,
  getWaveform
} from '../service/api';
import { DataOptions, DataTableHeader } from 'vuetify';

import { ResizeObserver } from '@juggle/resize-observer';
import { PlayerModule } from '../store/modules/player';
import { getModule } from 'vuex-module-decorators';
import { getPlayer } from '../service/audio';
import { errorEventBus } from '../service';
import SwipeTrack from './SwipeTrack.vue';

export interface SearchOptions {
  bpm?: {
    min?: number;
    max?: number;
  };

  filters?: Filter[];
}

interface TrackEntry {
  artist: string;
  title: string;
  bpm: string;
  energyLevel: string;
  duration: string;
  trackNumber?: number;
}

@Component({
  components: {
    SwipeTrack
  }
})
export default class TracksTable extends Vue {
  @Prop(Array)
  tracks: Track[] | undefined;

  @Prop(Object)
  searchOptions: SearchOptions | undefined;

  @Prop({
    type: Boolean,
    default: false
  })
  serverSide!: boolean;

  @Prop({ type: Boolean, default: false })
  loading!: boolean;

  @Prop()
  options!: Partial<DataOptions> | null;

  @Prop({ type: Boolean, default: true })
  uniqueTracks!: boolean;

  @Prop({ type: Boolean, default: false })
  allowQueue!: boolean;

  @Prop({ type: Boolean, default: false })
  player!: boolean;

  @Prop({ type: Boolean, default: false })
  overridePlay!: boolean;

  @Prop({ type: Boolean, default: false })
  deleteButton!: boolean;

  @Prop({ type: Boolean, default: false })
  addButton!: boolean;

  @Prop({ type: Boolean, default: false })
  dragHandle!: boolean;

  @Prop()
  playlistId: string | undefined;

  loadedTracks: Track[] | null = null;
  opts: Partial<DataOptions> = {
    itemsPerPage: 25
  };

  serverLoading: boolean = false;

  serverEntryCount: number | null = null;

  resizeObserver: ResizeObserver | null = null;
  tableHeight: number | null = null;

  get isLoading() {
    return this.loading || this.serverLoading;
  }

  get tableOptions() {
    return this.options ?? this.opts;
  }

  set tableOptions(opts: Partial<DataOptions>) {
    this.$set(this, 'opts', opts);
    this.$emit('update:options', opts);

    this.updateTable();
  }

  // Custom rendering is implemented,
  // align is not used from here.
  get headers(): DataTableHeader[] {
    return [
      ...(!this.player
        ? [
            {
              value: 'playButton',
              text: '',
              align: 'center',
              sortable: false
            }
          ]
        : ([] as any)),
      {
        value: 'artist',
        text: this.$t('app.tracks.table.headers.artist').toString(),
        align: 'center',
        sortable: true
      },
      {
        value: 'title',
        text: this.$t('app.tracks.table.headers.title').toString(),
        align: 'center',
        sortable: true
      },

      {
        value: 'bpm',
        align: 'center',
        text: this.$t('app.tracks.table.headers.bpm').toString(),
        sortable: true
      },
      {
        value: 'energyLevel',
        text: this.$t('app.tracks.table.headers.energyLevel').toString(),
        align: 'center',
        sortable: true
      },
      {
        value: 'duration',
        text: this.$t('app.tracks.table.headers.duration').toString(),
        align: 'center',
        sortable: true
      },
      ...(this.deleteButton
        ? [
            {
              value: 'deleteButton',
              text: '',
              align: 'center',
              sortable: false
            }
          ]
        : ([] as any)),
      ...(this.addButton
        ? [
            {
              value: 'addButton',
              text: '',
              align: 'center',
              sortable: false
            }
          ]
        : ([] as any))
    ];
  }

  get entries(): TrackEntry[] {
    if (!this.loadedTracks) {
      return [];
    }

    let entries = this.loadedTracks.map<TrackEntry>((t, i) => {
      return {
        artist: t.artist ?? '',
        title: t.title ?? '',
        bpm: t.bpm ?? '',
        energyLevel: t.energyLevel ?? '',
        duration: formatSeconds(t.duration),
        trackId: t.id,
        trackNumber: i
      };
    });

    if (!this.serverSide && this.searchOptions) {
      entries = entries.filter(e => {
        if (this.searchOptions?.filters) {
          for (const filter of this.searchOptions.filters) {
            if (filter.filter === 'generic') {
              continue;
            }
            for (const value of filter.values) {
              if (
                !(e as any)[filter.filter]
                  ?.toLowerCase()
                  .includes(value.toLowerCase().replace(/%/g, ''))
              ) {
                return false;
              }
            }
          }
        }

        if (this.searchOptions?.bpm?.min !== undefined) {
          if (parseInt(e.bpm) < this.searchOptions.bpm.min) {
            return false;
          }
        }

        if (this.searchOptions?.bpm?.max !== undefined) {
          if (parseInt(e.bpm) > this.searchOptions.bpm.max) {
            return false;
          }
        }
        return true;
      });

      const genericFilter = this.searchOptions?.filters?.find(
        f => f.filter === 'generic'
      );

      if (genericFilter) {
        entries = entries.filter(e => {
          for (const value of genericFilter.values) {
            if (
              e.artist
                ?.toLowerCase()
                .includes(value.toLowerCase().replace(/%/g, ''))
            ) {
              return true;
            }

            if (
              e.title
                ?.toLowerCase()
                .includes(value.toLowerCase().replace(/%/g, ''))
            ) {
              return true;
            }
          }

          return false;
        });
      }
    }

    return entries;
  }

  get hidePageCount() {
    if (!this.serverSide) {
      return false;
    }

    if (this.searchOptions?.bpm) {
      return false;
    }

    if (this.searchOptions?.filters?.length ?? 0 > 0) {
      return false;
    }

    return true;
  }

  isPlaying(trackId: string, index: number): boolean {
    if (this.uniqueTracks) {
      return this.playingTrackId === trackId;
    }
    const playerStore = getModule(PlayerModule, this.$store);

    if (playerStore.playlist?.id !== this.playlistId) {
      return false;
    }

    if (playerStore.currentTrackIndex === null) {
      return false;
    }

    return playerStore.currentTrackIndex === index;
  }

  // Index is used instead
  get playingTrackId() {
    const playerStore = getModule(PlayerModule, this.$store);

    if (playerStore.currentTrackIndex === null) {
      return null;
    }

    return playerStore.tracks[playerStore.currentTrackIndex]?.id ?? null;
  }

  async playTrack(trackId: string, index: number) {
    this.$emit('play', trackId, index);

    if (this.overridePlay) {
      return;
    }

    const playerStore = getModule(PlayerModule, this.$store);

    if (!this.player) {
      playerStore.setPlaylist(null);
    }

    if (this.uniqueTracks) {
      const index = this.loadedTracks!.findIndex(t => t.id === trackId);

      if (index === -1) {
        return;
      }

      playerStore.setTracks(clone(this.loadedTracks!));
      playerStore.setcurrentTrackIndex(index);

      const player = await getPlayer();
      await player.load(this.loadedTracks![index]);
      await player.play();
    } else {
      const track = playerStore.tracks[index];

      if (!track) {
        return;
      }

      playerStore.setcurrentTrackIndex(index);
      const player = await getPlayer();
      await player.load(track);
      await player.play();
    }
  }

  queueTrack(trackId: string) {
    const playerStore = getModule(PlayerModule, this.$store);

    const track = this.loadedTracks?.find(t => t.id === trackId);

    if (!track) {
      return;
    }

    playerStore.tracks.push(track);
  }

  async updateTable() {
    if (this.serverSide) {
      return this.fetchTracks();
    }

    this.$set(this, 'loadedTracks', this.tracks ?? null);
  }

  async fetchTracks() {
    const searchOpts: TrackSearchOptions = {
      page: this.opts.page,
      per_page: this.opts.itemsPerPage
    };

    if (this.opts.sortBy?.length ?? 0 > 0) {
      searchOpts.order_by = this.opts.sortBy![0];
    }

    if (this.opts.sortDesc?.length ?? 0 > 0) {
      searchOpts.order = this.opts.sortDesc![0] ? 'descending' : 'ascending';
    }

    if (this.searchOptions?.filters) {
      searchOpts.filters = this.searchOptions.filters;
    }

    if (this.searchOptions?.bpm?.min) {
      searchOpts.bpm_min = this.searchOptions.bpm.min;
    }

    if (this.searchOptions?.bpm?.max) {
      searchOpts.bpm_max = this.searchOptions.bpm.max;
    }

    this.serverLoading = true;

    try {
      const searchResult = await searchTracks(searchOpts);
      this.serverEntryCount = searchResult.items;
      this.$set(this, 'loadedTracks', searchResult.tracks);
      this.$emit('update:tracks', searchResult.tracks);
    } catch (e) {
      errorEventBus.$emit('error', e);
    } finally {
      this.serverLoading = false;
    }
  }

  resizeTable() {
    this.tableHeight = this.$el.clientHeight - 60;
  }

  autoResize() {
    let ignore = false;

    this.resizeObserver = new ResizeObserver((entries, observer) => {
      if (ignore) {
        ignore = false;
        return;
      }

      for (const entry of entries) {
        const { left, top, width, height } = entry.contentRect;
        this.resizeTable();
        ignore = true;
        return;
      }
    });

    this.resizeObserver.observe(this.$el);
  }

  @Watch('searchOptions')
  onSearchOptionsChanged() {
    this.updateTable();
  }

  @Watch('tracks')
  onTracksChanged() {
    this.updateTable();
  }

  mounted() {
    window.addEventListener('resize', this.resizeTable);
    this.autoResize();
    this.updateTable();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeTable);
    this.resizeObserver?.disconnect();
  }
}
