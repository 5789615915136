var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},[(_vm.swiperEnabled)?_c('div',{staticClass:"swiper-slide swipe-delete",style:({ height: '3.9rem' })}):_vm._e(),_c('div',{staticClass:"swiper-slide",style:({ height: '3.9rem' })},[_c('v-list-item',[_c('v-list-item-action',[(!_vm.playing)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('play')}}},[_c('v-icon',[_vm._v("mdi-play-circle-outline")])],1):_c('v-btn',{style:({
              pointerEvents: 'none'
            }),attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-play-circle")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{ref:"titleText",class:{
              'cc-blue--text': _vm.playing
            },style:({
              maxWidth: '50vw'
            })},[_vm._v(_vm._s(_vm.title))]),_c('v-list-item-subtitle',{class:{
              'cc-blue--text': _vm.playing
            }},[_vm._v(_vm._s(_vm.artist))])],1),_c('v-list-item-action',{class:{
            'cc-blue--text': _vm.playing
          }},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.duration))])],1),(_vm.addEnabled)?_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.$emit('add')}}},[_c('v-icon',[_vm._v("mdi-plus-circle")])],1):_vm._e(),(_vm.deleteEnabled)?_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.$emit('delete')}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),(_vm.dragEnabled)?_c('v-icon',{staticClass:"drag-handle ml-2"},[_vm._v("mdi-drag-horizontal")]):_vm._e()],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }